/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Map } from '@vis.gl/react-google-maps'
import { FC } from 'react'
import Header from 'src/components/02_molecules/Header/Header'
import MenuScannerMapControl from 'src/components/02_molecules/MenuScannerMapControl/MenuScannerMapControl'
import Page from 'src/components/03_organisms/Page/Page'
import { useUserContext } from 'src/context/UserContext'
import RoutePath from 'src/structures/Enums/RoutePath.enum'

type IMenuScannerDiscoverProperties = Record<string, null>

const MenuScannerDiscover: FC<IMenuScannerDiscoverProperties> = () => {
    const DISCOVER_MAP_ID = 'menuscanner-map-discover'
    const { user } = useUserContext()

    return (
        <Page
            className='h-full max-w-full'
            contentClassName='h-[calc(100vh-3.5rem)]'
            header={
                <Header
                    backButton={RoutePath.ROOT}
                    title={user?.supplier.name ?? undefined}
                />
            }
            footer={<MenuScannerMapControl mapId={DISCOVER_MAP_ID} />}>
            <div className='h-full w-full'>
                <Map
                    id={DISCOVER_MAP_ID}
                    defaultCenter={{ lat: 50.8503396, lng: 4.3517103 }}
                    defaultZoom={10}
                    disableDefaultUI
                    mapId='d106b6fe9c92e288'
                />
            </div>
        </Page>
    )
}

export default MenuScannerDiscover
