import { useQuery, useQueryClient } from '@tanstack/react-query'
import axios, { AxiosError } from 'axios'
import { FC, ReactNode, useCallback, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router'
import { getUser } from 'src/api/client/representatives/RepresentativesActions'
import LoadingScreen from 'src/components/04_pages/LoadingScreen/LoadingScreen'
import useSecret from 'src/hooks/useSecret'
import User from 'src/structures/Classes/User'
import QueryKey from 'src/structures/Enums/QueryKey.enum'
import RoutePath from 'src/structures/Enums/RoutePath.enum'
import IUserResponse from 'src/structures/Interfaces/IUserResponse'
import createGenericContext from 'src/utils/genericContext.utils'
import { isNotNullOrUndefined } from 'src/utils/guards.utils'

interface IUserContext {
    user: User | null | undefined
    isLoading: boolean
    isError: boolean
    isLoggedIn: boolean
    handleLogout: () => void
}

interface IUserProviderProperties {
    children: ReactNode
}

const [useUserContext, UserContextProvider] = createGenericContext<IUserContext>()

const UserProvider: FC<IUserProviderProperties> = ({ children }) => {
    const { secret, storeSecret, removeSecret } = useSecret()
    const [searchParams, setSearchParams] = useSearchParams()
    const navigate = useNavigate()
    const queryClient = useQueryClient()

    useEffect(() => {
        if (isNotNullOrUndefined(secret)) {
            axios.defaults.headers.common.Authorization = secret
            storeSecret(secret)

            if (searchParams.has('secret')) {
                searchParams.delete('secret')
                setSearchParams(searchParams)
            }
        }
    }, [secret, storeSecret, setSearchParams, axios])

    const {
        isLoading,
        isError,
        data: user,
    } = useQuery<IUserResponse, AxiosError, User>({
        queryKey: [QueryKey.USER],
        queryFn: getUser,
        select: (userResponse) => new User(userResponse),
        enabled: isNotNullOrUndefined(secret),
        retry: 1,
    })

    const handleLogout = useCallback(async () => {
        delete axios.defaults.headers.common.Authorization
        await removeSecret()
        queryClient.removeQueries()
        await navigate(RoutePath.ROOT)
    }, [removeSecret, navigate])

    const userContextValue = {
        user,
        isLoading,
        isError,
        isLoggedIn: isNotNullOrUndefined(secret) && isNotNullOrUndefined(user),
        handleLogout,
    }

    if (isLoading) {
        return <LoadingScreen />
    }

    return <UserContextProvider value={userContextValue}>{children}</UserContextProvider>
}

export { UserProvider, useUserContext }
