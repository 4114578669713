import { useCallback, useState } from 'react'
import { useSearchParams } from 'react-router'
import useStore from 'src/store/useStore'

const useSecret = () => {
    const storedSecret = useStore((state) => state.secret)
    const storeSecret = useStore((state) => state.setSecret)
    const removeSecret = useStore((state) => state.removeSecret)
    const [searchParams] = useSearchParams()

    const [secret, setSecret] = useState(searchParams.get('secret') ?? storedSecret ?? null)

    const deleteSecret = useCallback(async () => {
        removeSecret()
        setSecret(null)
        await useStore.persist.rehydrate()
    }, [removeSecret])

    return {
        secret,
        storedSecret,
        querySecret: searchParams.get('secret'),
        storeSecret,
        removeSecret: deleteSecret,
    }
}

export default useSecret
