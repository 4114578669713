import { FC } from 'react'

interface ITargetIconProperties {
    className?: string
}

const TargetIcon: FC<ITargetIconProperties> = ({ className }) => (
    <svg
        stroke='currentColor'
        fill='currentColor'
        strokeWidth='0'
        className={className}
        viewBox='0 0 512 512'>
        <path d='M172.7 19.27l-25.4 25.46L256 153.5 364.7 44.73l-25.4-25.46L256 102.5l-83.3-83.23zM44.73 147.3l-25.46 25.4L102.5 256l-83.23 83.3 25.46 25.4L153.5 256 44.73 147.3zm422.47 0L358.6 256l108.6 108.7 25.4-25.4-83.2-83.3 83.2-83.3-25.4-25.4zM256 358.5L147.3 467.3l25.4 25.4 83.3-83.2 83.3 83.2 25.4-25.4L256 358.5z'></path>
    </svg>
)

export default TargetIcon
