import { FC } from 'react'
import { useUserContext } from '../../../context/UserContext'
import AuthenticatedRoutes from '../AuthenticatedRoutes/AuthenticatedRoutes'
import UnAuthenticatedRoutes from '../UnAuthenticatedRoutes/UnAuthenticatedRoutes'
import { APIProvider } from '@vis.gl/react-google-maps'

type IRouterProperties = Record<string, null>

const Router: FC<IRouterProperties> = () => {
    const { isLoggedIn } = useUserContext()

    if (isLoggedIn) {
        return (
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            <APIProvider apiKey={import.meta.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
                <AuthenticatedRoutes />
            </APIProvider>
        )
    }

    return <UnAuthenticatedRoutes />
}

export default Router
