import { useMap } from '@vis.gl/react-google-maps'
import { FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import Button, { ButtonStyle } from 'src/components/01_atoms/Button/Button'
import TargetIcon from 'src/components/01_atoms/Icon/TargetIcon'
import { isNotNullOrUndefined } from 'src/utils/guards.utils'

interface IMenuScannerMapControlProperties {
    mapId: string
}

const MenuScannerMapControl: FC<IMenuScannerMapControlProperties> = ({ mapId }) => {
    const { t } = useTranslation()
    const map = useMap(mapId)

    const [isLoading, setIsLoading] = useState(false)

    const handleLocationError = useCallback((browserHasGeolocation: boolean) => {
        setIsLoading(false)
        toast.error(
            browserHasGeolocation ? 'The Geolocation service failed.' : "Your browser doesn't support geolocation.",
            {
                toastId: mapId,
            }
        )
    }, [])

    const handleCurrentLocationRequest = useCallback(() => {
        if (isNotNullOrUndefined(map)) {
            setIsLoading(true)
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position: GeolocationPosition) => {
                        map.setCenter({
                            lat: position.coords.latitude,
                            lng: position.coords.longitude,
                        })
                        map.setZoom(18)
                        setIsLoading(false)
                    },
                    () => handleLocationError(true)
                )
            } else {
                // Browser doesn't support Geolocation
                handleLocationError(false)
            }
        }
    }, [map, handleLocationError])

    useEffect(() => {
        handleCurrentLocationRequest()
    }, [handleCurrentLocationRequest])

    return (
        <div className='fixed flex items-center flex-col justify-center bottom-0 left-0 right-0 p-6 animate-animateUp'>
            <Button
                loading={isLoading}
                onClick={handleCurrentLocationRequest}
                className='w-full max-w-screen-lg overflow-hidden'
                buttonStyle={ButtonStyle.PRIMARY}>
                <TargetIcon className='size-4 min-w-4 m-0 mr-2' />
                <span className='overflow-hidden text-ellipsis inline'>{t('scansession.button.centerMap')}</span>
            </Button>
        </div>
    )
}
export default MenuScannerMapControl
