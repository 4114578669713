import { FC, ReactNode, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import CloseIcon from 'src/components/01_atoms/Icon/CloseIcon'
import { useUserContext } from 'src/context/UserContext'
import useStore from 'src/store/useStore'

interface IDrawerMenuProperties {
    children: ReactNode
}

const DrawerMenu: FC<IDrawerMenuProperties> = ({ children }) => {
    const { t } = useTranslation()
    const { handleLogout } = useUserContext()
    const setDrawerMenuIsOpen = useStore((state) => state.setDrawerMenuIsOpen)

    const handleCloseMenu = useCallback(() => {
        setDrawerMenuIsOpen(false)
    }, [setDrawerMenuIsOpen])

    return (
        <section className='fixed z-60 top-0 bottom-0 left-0 w-full flex flex-col bg-gray-200 animate-slideInLeft'>
            <header className='w-full flex items-center justify-end bg-black h-14 p-4 min-h-14'>
                <button
                    onClick={handleCloseMenu}
                    type='button'>
                    <CloseIcon className='size-8 text-white' />
                </button>
            </header>
            <div className='flex-1'>
                <div className='overflow-auto'>{children}</div>
            </div>
            <div className='h-14 min-h-14 px-4'>
                <button
                    onClick={handleLogout}
                    type='button'
                    className='text-rose-500 border-t-rose-500 border-t px-4 flex items-center justify-center w-full text-center h-full'>
                    <span className='text-center text-lg'>{t('button.logout')}</span>
                </button>
            </div>
        </section>
    )
}

export default DrawerMenu
