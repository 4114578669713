import { useMutation } from '@tanstack/react-query'
import { FC, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router'
import { toast } from 'react-toastify'
import { createScanSession } from 'src/api/client/scansessions/ScansessionsActions'
import Button, { ButtonStyle } from 'src/components/01_atoms/Button/Button'
import MapIcon from 'src/components/01_atoms/Icon/MapIcon'
import PlusIcon from 'src/components/01_atoms/Icon/PlusIcon'
import Header from 'src/components/02_molecules/Header/Header'
import Hero from 'src/components/02_molecules/Hero/Hero'
import MenuScannerHistory from 'src/components/03_organisms/MenuScannerHistory/MenuScannerHistory'
import Page from 'src/components/03_organisms/Page/Page'
import { useUserContext } from 'src/context/UserContext'
import RoutePath from 'src/structures/Enums/RoutePath.enum'
import { isNotNullOrUndefined } from 'src/utils/guards.utils'

type IMenuScannerDashboardProperties = Record<string, null>

const MenuScannerDashboard: FC<IMenuScannerDashboardProperties> = () => {
    const { t } = useTranslation()
    const { user } = useUserContext()
    const navigate = useNavigate()

    const createScanSessionMutation = useMutation({
        mutationFn: createScanSession,
    })

    const [isCreateingScanSession, setIsCreateingScanSession] = useState<boolean>(false)

    const handleNewScanSession = useCallback(() => {
        setIsCreateingScanSession(true)
        createScanSessionMutation.mutate(undefined, {
            onSuccess: (data) => {
                void navigate(RoutePath.MENU_SCANNER.replace(':sessionId', data.id))
            },
            onError: () => {
                toast.error(t('general.somethingWentWrong'))
                setIsCreateingScanSession(false)
            },
        })
    }, [navigate])

    return (
        <Page
            header={<Header title={user?.supplier.name ?? undefined} />}
            footer={
                <div className='h-48'>
                    <div className='fixed flex items-center flex-col justify-center bottom-0 left-0 right-0 p-6 animate-animateUp'>
                        <Link
                            className='block w-full max-w-screen-lg mb-2'
                            to={RoutePath.DISCOVER}>
                            <Button
                                loading={isCreateingScanSession}
                                className='w-full !bg-gray-100 !text-gray-500'
                                buttonStyle={ButtonStyle.SECONDARY}>
                                <MapIcon className='size-4 min-w-4 m-0 mr-2' />
                                <span className='overflow-hidden text-ellipsis inline'>
                                    {t('scansession.button.discover')}
                                </span>
                            </Button>
                        </Link>
                        <Button
                            loading={isCreateingScanSession}
                            onClick={handleNewScanSession}
                            className='w-full max-w-screen-lg overflow-hidden'
                            buttonStyle={ButtonStyle.PRIMARY}>
                            {isCreateingScanSession ? null : <PlusIcon className='size-4 min-w-4 m-0 mr-2' />}
                            <span className='overflow-hidden text-ellipsis inline'>
                                {t('scansession.button.newScanSession')}
                            </span>
                        </Button>
                    </div>
                </div>
            }>
            <Hero>
                <h1 className='h3 text-center'>{`${t('general.hello')}${isNotNullOrUndefined(user) ? ', ' + user?.firstname : ''}!`}</h1>
            </Hero>
            <MenuScannerHistory />
        </Page>
    )
}

export default MenuScannerDashboard
