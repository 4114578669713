/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable simple-import-sort/imports */
// import on top, or it will overwrite all our custom tailwind utility classes
import './tailwind-init.scss'
import './tailwind/tailwind-additions.scss'
/* eslint-enable simple-import-sort/imports */
import * as Sentry from '@sentry/react'
import { StrictMode } from 'react'
import ReactDOM from 'react-dom/client'

import './i18n-config'
import App from './App'

//if (import.meta.env.MODE !== 'development') {
Sentry.init({
    dsn: import.meta.env.REACT_APP_SENTRY_DSN,
    environment: import.meta.env.REACT_APP_ENVIRONMENT,
    sampleRate: import.meta.env.REACT_APP_SENTRY_SAMPLE_RATE,
    integrations: [Sentry.browserTracingIntegration()],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0,
})
//}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = ReactDOM.createRoot(document.getElementById('root')!)

root.render(
    <StrictMode>
        <App />
    </StrictMode>
)
