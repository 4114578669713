import { FC, ReactNode } from 'react'
import { isNotNullOrUndefined } from 'src/utils/guards.utils'
import { twMerge } from 'tailwind-merge'

interface IPageProperties {
    header?: ReactNode
    children: ReactNode
    footer?: ReactNode
    className?: string
    contentClassName?: string
}

const Page: FC<IPageProperties> = ({ header, children, footer, className, contentClassName }) => (
    <section className={twMerge('mx-auto max-w-screen-lg', className)}>
        {isNotNullOrUndefined(header) ? header : null}
        <div className={twMerge(contentClassName)}>{children}</div>
        {isNotNullOrUndefined(footer) ? footer : null}
    </section>
)

export default Page
